import {
	faMedium,
	faTwitter,
	faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
	faGlobe,
	faEnvelope,
	faCoffee,
} from "@fortawesome/free-solid-svg-icons";

export const ICONS = [
	faMedium,
	faTwitter,
	faGithub,
	faGlobe,
	faEnvelope,
	faCoffee,
];
